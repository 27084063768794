@import 'base';
@import 'sider';
@import "components/req";
@import "projects/req";
@import "views/req";

.ant-alert-error {
  background-color: #FF0000;
  border: 1px solid #ffa39e;
  color: #FFF;
}


.material--preview {
  display: flex;
  flex-direction: column;
  .btn--group {
    margin-bottom: 12px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}


.media--view .media__item a.preview > div.image--expired, .media--list_item .image--expired {
  position: absolute;
  top: 28px;
  left: 4px;
  margin-left: 0 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f50;
}

.media--list_item .image--expired {
  top: 4px;
}

.categories__report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  .categories__report--item {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    padding: 8px 12px;
    .category__title {
      font-weight: 600;
      font-size: 16px;
    }
    & + .categories__report--item {
      margin-left: 20px;
    }
  }
}


.preview--container {
  .preview--btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .preview--container__iframe {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    transition: width 0.3s ease 0s;
    margin: 0 auto;
    iframe {
      border: 0;
      width: 100%;
      height: 800px;
      margin: 0 auto;
    }
  }
}

.row__with-btn .ant-form-item-children{
  display: flex;
  flex-direction: row;
  .btn--acadd {
    margin-left: 12px;
  }
}

.form__options--container--wrp {
  position: sticky;
  top: 10px;
}

.does_not_image {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #FF0000;
  margin-right: 10px;
}

.image_exists {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #00FF00;
  margin-right: 10px;
}