.editor_mgallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #dfdfdf;
  padding: 12px;

  .mgallery_filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .ant-pagination {
      flex: 1;
      max-width: 500px;
      margin-left: 10px;
      flex-direction: row;
      display: flex;
      width: 100%;
    }
  }
  .mgallery__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mgallery__list--item {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFF;
    border-radius: 6px;
    margin-top: 10px;
    .list--item__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 4px 10px;
      border-bottom: 1px solid #dfdfdf;
    }
    .list--item__images {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 10px 10px 10px 0px;
      > div {
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        margin-top: 10px;
        margin-left: 10px;

      }
    }
  }
}
