.comments {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.comments--block {
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  border-radius: 6px;
}
.comments--block.table {
  margin-top: 12px;
}
.comments--block.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.comments--block.filter > button {
  margin-right: 12px;
}
.comments--block .platform {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.comments--block .platform img {
  width: 20px;
  height: auto;
  margin-right: 4px;
}