.media--view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;

  .mg-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 12px;


    .pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      .views {
        margin-right: 10px;
      }
    }



    .actions {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #FFF;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      padding: 8px;
      .actions--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & + .actions--row {
          margin-top: 4px;
        }
        @media (max-width: 768px) {
          .pagination {
            > div {
              display: none !important;
            }
            flex-direction: column !important;
          }
        }
      }
      .views {
        > button + button {
          margin-left: 4px;
        }
      }
    }

    .list {
      display: flex;
      position: relative;
      z-index: 1;

    

      &.list__card {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        height: 100%;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }
  }

}

.media__wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: calc(26% - 16px);
  width: 100%;
  padding: 4px;
  .media__item {
    flex: 1;
  }
  @media (max-width: 1280px) {
    margin-top: 4px;
    flex-basis: calc(30% - 16px);
  }
  @media (max-width: 1024px) {
    flex-basis: calc(34.5% - 16px);
  }
}
.media__item {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;





  &.item__list {
    flex-direction: row;
    margin-top: 8px;
    .item__info {
      display: flex;
      flex-direction: column;
      padding: 12px;
      flex: 1;
      .title {
        font-weight: 900;
        padding: 0;
      }
      .title + .description {
        margin-top: 8px;
      }
    }
    .image {
      display: flex;
      flex-direction: row-reverse;
      overflow: auto;

      a.preview {
        //min-width: 240px;
        //max-width: 240px;
        width: 240px;
        min-width: 240px;

        display: block;
      }
      .item__actions {
        position: relative;
        padding: 4px;
        button {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .image {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    .bookmark_action {
      position: absolute;
      top: 4px;
      z-index: 999;
      left: 4px;
    }
    &:hover {
      .item__actions {
        button {
          transform: translateX(0);
          opacity: 1;
        }
      }
      a.preview {
        &:after {
          background-color: rgba(0, 0, 0, 0.3)
        }
      }
    }
    .item__actions {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      display: flex;
      margin-right: 4px;
      flex-direction: column;
      justify-content: center;
      height: 100%;


      button {
        transform: translateX(120%);
        opacity: 0;
        transition: all 0.2s ease 0s;
        &:nth-child(2) {
          transition: all 0.2s ease 0.1s;
        }
        &:nth-child(3) {
          transition: all 0.2s ease 0.2s;
        }
      }
      > button + button {
        margin-top: 4px;
      }
    }
  }

  a.preview {
    display: inline-block;
    width: 100%;
    padding-bottom: 56.6%;
    background-size: cover;
    background-position: center;
    font-size: 0;
    position: relative;
    background-color: #000000;

    .created_at {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 4px;
      margin: 0;
      width: 100%;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #FFF;

    }

    .image--usage {
      position: absolute;
      bottom: 4px;
      left: 0;
      z-index: 2;
      background: rgba(255, 0, 0, 1);
      width: 20px;
      height: 20px;
      border-radius: 4px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      color: #FFF;
    }

    &.type_pdf {
      background-position: top center;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease 0s;
    }

    > div {
      margin: 4px 0 0 4px;
      position: relative;
      z-index: 2;
    }
    &.can_bookmark {
      > div {
        margin-left: 24px;
        &.created_at {
          margin-left: 0;
        }
      }
    }
  }

  .title {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.3;
  }
}

.mg-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  form {
    display: flex;
    flex-direction: row;
    width: 100%;
    .search {
      flex: 1;
      display: flex;
      .ant-form-item-control-wrapper {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .ant-calendar-picker {
        width: 100% !important;
      }
    }
  }
}


.mgr__upload-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  > .actions {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 12px;
    h3 {
      margin: 0;
      padding: 0;
    }
  }

  .form--items {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      align-items: center;
      > button {
        margin-left: 20px;
      }
    }

    .mg--upload--item {
      background-color: #FFF;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      padding: 12px;
      margin-top: 12px;
      margin-left: 12px;
      flex: 1;
      .ant-spin-nested-loading {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .ant-spin-container {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      .preview {
        max-width: 400px;
        width: 400px;
        margin-right: 20px;
        .actions {
          margin-top: 8px;
        }
        img, video {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .form--actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    button + button {
      margin-left: 20px;
    }
  }
}


.media--view .media__item a.preview > div.file-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 20px;
    text-overflow: visible;
    span {
      border: 2px solid #FFF;
      border-radius: 100%;
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
}
.media--view .media__item a.preview{
  position: relative;
  z-index: 1;
}
.media--view .media__item a.preview > div, .media--view .media__item .image .item__actions {
  z-index: 4;
}



.modal_media_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.item__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -3px;
}

.modal__list--filter {
  padding: 20px 12px;
  border: 1px solid #dfdfdf;
  margin-top: 10px;
  margin-bottom: 6px;
  background-color: #FFF;
  border-radius: 5px;
  position: sticky;
  top: 10px;
  z-index: 9999;
}

.image {
  position: relative;
  .image__tag {
    position: absolute;
    z-index: 2;
    top: 4px;
    left: 4px;
  }
}