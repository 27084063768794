

.project_vm {
  .logo {
    background-color: transparent;
    background-image: url('/images/logos/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  background-color: #202c37;
}
.ant-layout-header, .ant-layout-sider, .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  background-color: #202c37;
}
//