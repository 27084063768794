.media--view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
}
.media--view .mg-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 12px;
}
.media--view .mg-list .pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.media--view .mg-list .pagination .views {
  margin-right: 10px;
}
.media--view .mg-list .actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 8px;
}
.media--view .mg-list .actions .actions--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.media--view .mg-list .actions .actions--row + .actions--row {
  margin-top: 4px;
}
@media (max-width: 768px) {
  .media--view .mg-list .actions .actions--row .pagination {
    flex-direction: column !important;
  }
  .media--view .mg-list .actions .actions--row .pagination > div {
    display: none !important;
  }
}
.media--view .mg-list .actions .views > button + button {
  margin-left: 4px;
}
.media--view .mg-list .list {
  display: flex;
  position: relative;
  z-index: 1;
}
.media--view .mg-list .list.list__card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
}
@media (max-width: 768px) {
  .media--view .mg-list .list.list__card {
    flex-direction: column;
  }
}

.media__wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: calc(26% - 16px);
  width: 100%;
  padding: 4px;
}
.media__wrapper .media__item {
  flex: 1;
}
@media (max-width: 1280px) {
  .media__wrapper {
    margin-top: 4px;
    flex-basis: calc(30% - 16px);
  }
}
@media (max-width: 1024px) {
  .media__wrapper {
    flex-basis: calc(34.5% - 16px);
  }
}

.media__item {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;
}
.media__item.item__list {
  flex-direction: row;
  margin-top: 8px;
}
.media__item.item__list .item__info {
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex: 1;
}
.media__item.item__list .item__info .title {
  font-weight: 900;
  padding: 0;
}
.media__item.item__list .item__info .title + .description {
  margin-top: 8px;
}
.media__item.item__list .image {
  display: flex;
  flex-direction: row-reverse;
  overflow: auto;
}
.media__item.item__list .image a.preview {
  width: 240px;
  min-width: 240px;
  display: block;
}
.media__item.item__list .image .item__actions {
  position: relative;
  padding: 4px;
}
.media__item.item__list .image .item__actions button {
  opacity: 1;
  transform: translateX(0);
}
.media__item .image {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.media__item .image .bookmark_action {
  position: absolute;
  top: 4px;
  z-index: 999;
  left: 4px;
}
.media__item .image:hover .item__actions button {
  transform: translateX(0);
  opacity: 1;
}
.media__item .image:hover a.preview:after {
  background-color: rgba(0, 0, 0, 0.3);
}
.media__item .image .item__actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  margin-right: 4px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.media__item .image .item__actions button {
  transform: translateX(120%);
  opacity: 0;
  transition: all 0.2s ease 0s;
}
.media__item .image .item__actions button:nth-child(2) {
  transition: all 0.2s ease 0.1s;
}
.media__item .image .item__actions button:nth-child(3) {
  transition: all 0.2s ease 0.2s;
}
.media__item .image .item__actions > button + button {
  margin-top: 4px;
}
.media__item a.preview {
  display: inline-block;
  width: 100%;
  padding-bottom: 56.6%;
  background-size: cover;
  background-position: center;
  font-size: 0;
  position: relative;
  background-color: #000000;
}
.media__item a.preview .created_at {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px;
  margin: 0;
  width: 100%;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #FFF;
}
.media__item a.preview .image--usage {
  position: absolute;
  bottom: 4px;
  left: 0;
  z-index: 2;
  background: rgb(255, 0, 0);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #FFF;
}
.media__item a.preview.type_pdf {
  background-position: top center;
}
.media__item a.preview:after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
}
.media__item a.preview > div {
  margin: 4px 0 0 4px;
  position: relative;
  z-index: 2;
}
.media__item a.preview.can_bookmark > div {
  margin-left: 24px;
}
.media__item a.preview.can_bookmark > div.created_at {
  margin-left: 0;
}
.media__item .title {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.3;
}

.mg-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mg-filter form {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mg-filter form .search {
  flex: 1;
  display: flex;
}
.mg-filter form .search .ant-form-item-control-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .mg-filter form {
    flex-direction: column;
  }
  .mg-filter form .ant-calendar-picker {
    width: 100% !important;
  }
}

.mgr__upload-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mgr__upload-form > .actions {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 12px;
}
.mgr__upload-form > .actions h3 {
  margin: 0;
  padding: 0;
}
.mgr__upload-form .form--items {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.mgr__upload-form .form--items .ant-form-item-children {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mgr__upload-form .form--items .ant-form-item-children > button {
  margin-left: 20px;
}
.mgr__upload-form .form--items .mg--upload--item {
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  padding: 12px;
  margin-top: 12px;
  margin-left: 12px;
  flex: 1;
}
.mgr__upload-form .form--items .mg--upload--item .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mgr__upload-form .form--items .mg--upload--item .ant-spin-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mgr__upload-form .form--items .mg--upload--item .preview {
  max-width: 400px;
  width: 400px;
  margin-right: 20px;
}
.mgr__upload-form .form--items .mg--upload--item .preview .actions {
  margin-top: 8px;
}
.mgr__upload-form .form--items .mg--upload--item .preview img, .mgr__upload-form .form--items .mg--upload--item .preview video {
  width: 100%;
  height: auto;
}
.mgr__upload-form .form--actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
.mgr__upload-form .form--actions button + button {
  margin-left: 20px;
}

.media--view .media__item a.preview > div.file-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 20px;
  text-overflow: visible;
}
.media--view .media__item a.preview > div.file-progress span {
  border: 2px solid #FFF;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.media--view .media__item a.preview {
  position: relative;
  z-index: 1;
}

.media--view .media__item a.preview > div, .media--view .media__item .image .item__actions {
  z-index: 4;
}

.modal_media_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.item__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -3px;
}

.modal__list--filter {
  padding: 20px 12px;
  border: 1px solid #dfdfdf;
  margin-top: 10px;
  margin-bottom: 6px;
  background-color: #FFF;
  border-radius: 5px;
  position: sticky;
  top: 10px;
  z-index: 9999;
}

.image {
  position: relative;
}
.image .image__tag {
  position: absolute;
  z-index: 2;
  top: 4px;
  left: 4px;
}