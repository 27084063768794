.regions-materials {
  width: 100%;
  padding: 12px;
}
.regions-materials .region_cover {
  max-width: 120px;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.regions-materials .box {
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.regions-materials .box + .box {
  margin-top: 12px;
}
.regions-materials .title {
  font-size: 1.2em;
  background-color: #FFF;
  padding: 12px;
  font-weight: 600;
}
.regions-materials .table {
  background-color: #FFF;
}
.regions-materials .table_actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.regions-materials .table_actions > button {
  margin-right: 20px;
}
.regions-materials .region_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.regions-materials .region_actions button + button {
  margin-left: 10px;
}