.rss_controller--wrapper {
	display: grid;
	grid-template-columns: 4fr 6fr;
	grid-gap: 12px;
}

.rss_controller {
	display: grid;
	grid-template-columns: 1fr;
	padding: 12px;
}

.rss_controller {
	.filter {
		background: #FFF;
		padding: 4px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		width: 100%;
		.ant-form-item {
			margin-bottom: 0;
		}
	}
	.filter--row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		.search--input {
			flex: 1;
			margin-right: 12px;
		}
		.sources {
			flex: 1;
		}
	}
	.filter--column {
		display: flex;
		flex-direction: column;
		flex: 1;
		&.action--column-name {
			margin-left: 12px;
			max-width: 120px;
		}
	}
}

.rss_feeds {
	max-height: calc(100vh - 260px);
	overflow: scroll;
}

.rss_item {
	display: flex;
	flex-direction: column;
	background: #FFF;
	border-radius: 4px;
	padding: 4px 8px;
	transition: all 0.3s ease 0s;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
	&:hover, &.selected {
		background-color: rgba(24, 23, 23, .13)
	}
	cursor: pointer;
	& + .rss_item {
		margin-top: 4px;
	}
	.title {
		font-weight: 600;
		font-size: 1rem;
	}
	.description {
		font-size: 0.76rem;
	}
	.feed__footer {
		margin-top: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-size: 0.73rem;
	}
}

.rss_feeds {
	margin: 12px 0;
}

.feed__item {
	margin: 12px 0;
	background: #FFF;
	border-radius: 4px;
	padding: 4px 8px;
	transition: all 0.3s ease 0s;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
	width: 100%;
	max-height: calc(100vh - 260px);
	overflow-y: scroll;
	img {
		width: 100%;
		height: auto;
		margin-bottom: 12px;
	}

	.title {
		font-size: 1.2rem;
		font-weight: 800;
	}

	.block--info {
		margin: 12px 0;
		padding: 4px;
		border-top: 1px solid #dfdfdf;
		border-bottom: 1px solid #dfdfdf;
		font-size: 0.83rem;
		span {
			font-weight: 600;
		}
	}

}