.regions-materials {
  width: 100%;
  padding: 12px;
  .region_cover {
    max-width: 120px;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  .box {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    & + .box {
      margin-top: 12px;
    }
  }
  .title{
    font-size: 1.2em;
    background-color: #FFF;
    padding: 12px;
    font-weight: 600;
  }

  .table {
    background-color: #FFF;
  }

  .table_actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    > button {
      margin-right: 20px;
    }
  }

  .region_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    button + button {
      margin-left: 10px;
    }
  }
}
