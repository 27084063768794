.comments {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.comments--block {
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    background-color: #FFF;
    border-radius: 6px;
    &.table {
        margin-top: 12px;
    }
    &.filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        > button {
            margin-right: 12px;
        }
    }

    .platform {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
            width: 20px;
            height: auto;
            margin-right: 4px;
        }
    }
}
