.mgallery {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mgallery > div {
  flex: 1;
}
.mgallery .cover--backgrounds {
  height: 160px !important;
}
.mgallery .media--list .media--list_item .image--info {
  line-height: 1.3;
}

.mgallery__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  max-width: 720px;
}
.mgallery__list > div {
  border: 1px solid #dfdfdf;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.02);
}
.mgallery__list > div + div {
  margin-top: 20px;
}
.mgallery__list .item__info {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 4px;
}
.mgallery__list .item__info .cover {
  width: 160px;
  min-width: 160px;
  height: 90px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.mgallery__list .item__info .item__info__text {
  padding: 12px;
  font-size: 14px;
  line-height: 1.3;
  word-break: break-word;
}
.mgallery__list .item__sort, .mgallery__list .item__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 100%;
}
.mgallery__list .item__sort button + button, .mgallery__list .item__actions button + button {
  margin-top: 6px;
  padding: 4px;
}