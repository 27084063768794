.wlang__row {
  .ant-avatar-image {
    margin-left: 4px;
    border-radius: 4px;
  }
}

.widget--filter {
  min-width: 230px;
  > .ant-select {
    width: 100%;
  }
  margin: 0 10px;
}