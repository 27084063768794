.instruments-dnd .image__list {
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	margin: 12px;
	padding-bottom: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	
	.image__add--btn {
		border: 1px solid rgba(0, 0, 0, 0.3);
		border-radius: 6px;
		width: 80px;
		height: 80px;
		font-size: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		
	}

	.image__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 4px 4px;
		width: 100%;
		margin-right: 12px;
		.image__item {
			padding-bottom: 56.6%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 4px;
			position: relative;
			background-color: #000;
			.image__actions {
				position: absolute;
				width: 100%;
				text-align: center;
				bottom: 0;
				padding: 4px;
				button + button {
					margin-left: 2px;
				}
			}
		}
	}
}

.instruments-dnd .list.list__card {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 12px 12px;
	margin-top: 12px;
	.media__item {
		display: flex;
		flex-direction: column;
		background: #FFF;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		height: 100%;
		.image {
			width: 100%;
			padding-bottom: 56.6%;
			background-size: cover;
			margin-bottom: 4px;
		}
		.title {
			padding: 6px;
		}
		.item__actions{ 
			padding: 4px;
			button {
				width: 100%;
			}
		}
	}
}

.tui-image-editor-header-logo, .tui-image-editor-header-buttons {
	display: none;
}

.instruments-dnd .image--action {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px;
}

.dnd-images {
	background-color: #FFF;
	margin: 12px;
	.ant-spin-container, > div.ant-spin-nested-loading{
		width: 100%;
		height: 100%;
	}
	.dnd-wrapper{
		display: flex;
		flex-direction: row;
		height: 100%;
	}
}
.dnd--block{
	width: 100%;
	height: 100%;
	background-color: #FFF;
}

.dnd-image {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1;
	background-color: #FF0000;
	overflow: hidden;
	position: relative;

	.dnd-drag {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius:4px;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		background-color: #FFF;
	}

	.actions--header {
		display: flex;
		flex-direction: row;
		padding: 4px;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		.scale {
			flex: 1;
			margin-left: 4px;
		}
	}

	.image {
		background-position: center;
		background-repeat: no-repeat;
		width: 100%; 
		height: 100%;
		position: absolute;

		background-size: contain;
		top: 0;
		left: 0;
	}

	
	.move-axist-y {
		position: absolute;
		padding: 20px 4px;
		height: 50%;
		top: 240px;
		right: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 20px;

	}

	.move-axist-x {
		position: absolute;
		padding: 4px 20px;
		width: 80%;
		bottom: 20px;
		transform: translateX(50%);
		left: -30%;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 20px;
	}

	& + .dnd-image {
		margin-left: 10px;
	}
}


.dnd-images.is_download {
	.actions--header, .move-axist-x, .move-axist-y {
		display: none;
	}
	.dnd-image {
		background-color: transparent;
	}
}

.dnd--actions {
	margin-top: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}